import React from "react";
import Helmet from "react-helmet";

import logo from "../images/theCidXlogo.png";
//  import favicon from "../img/favicon.ico";

let theForm = `<form name="contact" method="POST" data-netlify="true" enctype="application/x-www-form-urlencoded" id="netlify">
  <p>
    <label>Your Name:* <input type="text" name="name" required /></label>
  </p>
  <p>
    <label>Your Phone Number: <input type="text" name="phone" /></label>
  </p>
  <p>
    <label>Your Email: <input type="text" name="email" /></label>
  </p>
  <p>
    <label>Your Message:* <textarea id="message" name="message" required></textarea></label>
  </p>
  <p>
    <button type="submit">Send</button>
  </p>
</form>`;

function createForm() { return {__html: theForm}; };

export default function netlifyform() {
  return (
    <React.Fragment>
      <h2 id="contactHeader">Contact <a href="https://thecidx.com">The Cid X</a></h2>
      <div id="formContainer">
        <section id="logoContainer">
          <a href="https://thecidx.com"><img src={logo} alt="logo" id="logo" /></a>
        </section>
        <div id="formContent" dangerouslySetInnerHTML={createForm()} />
      </div>
      <style>
        {`
          form#netlify {
            font-family: SFMonoRegular, Hack, Fira Code, Courier, Menlo, monospace;
          }
          h2#contactHeader {
            font-family: Menlo, Futura, monospace;
          }
          h2#contactHeader a {
            color: inherit;
          }
          h2#contactHeader, form#netlify {
            padding-block-start: calc(2vw + 5pt);
            padding-inline-start: calc(2vw + 5pt);
            padding-block-end: calc(0.15vw + 2px);
            padding-inline-end: calc(1vw + 2px);
            background: LavenderBlush;
            margin-left: calc(3vw);
          }
          button {
            background: snow;
          }
          body {
            background: snow;
          }
          form#netlify input {
            height: calc(3vh + 10px);
            font-size: calc(1.5rem);
          }
          @media (min-width: 800px) {
            div#formContainer {
              display: flex;
            }
            section#logoContainer {
              width: calc(500pt);
            }
            button {
              width: calc(10vw + 15pt);
              height: calc(4vh + 5pt);
              background: Gainsboro;
              border-radius: 7pt;
            }
            
            div#formContent {
              
              width: calc(500pt);

            }
            img#logo {
              margin-inline-start: calc(3.5vw + 5pt);
              width: calc(30vw + 10pt);
            }
            textarea#message {
              height: calc(32vh + 10px);
              width: calc(28vw + 10pt);
              font-size: calc(1.5rem);
            }
          }
          @media (max-width: 799px) {
            div#formContainer {
              display: block;
            }
            img#logo {
              width: calc(78vw + 10pt);
              margin-inline-start: calc(0.5vw + 9pt);
            }
            textarea#message {
              height: calc(32vh + 10px);
              width: calc(10vw + 350px);
              font-size: calc(1.5rem);
            }
            section#logoContainer {
              width: calc(80vw + 11px);
            }
            button {
              width: calc(13vw + 80pt);
            }
          }
        `}
      </style>
      <Helmet>
        <title>
          theCidX - contact
        </title>
        <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0"
          />
          {/* <link rel="icon" href={favicon} sizes="32x32"></link> */}
      </Helmet>
    </React.Fragment>
  )
}