import React from "react";
import Netlifyform from "../components/netlifyform";

export default function Contact() {
  return (
    <React.Fragment>
      <Netlifyform />
    </React.Fragment>
  )
}
